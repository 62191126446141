import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import './FrontIndex.css'
import FrontCient from './FrontClient'
import FrontOption from './FrontOption'
import FrontProduct from './FrontProduct'
import FrontFinalList from './FrontFinalList'
import * as url from '../include/var.js'
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';


function FrontIndex({ evtId }) {
    const [clickClient, setClickClient] = useState(true); // Afficher le contenu de base
    const [clickProduct, setClickProduct] = useState(false);
    const [clickOption, setClickOption] = useState(false);
    const [posts, setPosts] = useState()
    const [finalLists, setFinalLists] = useState()
    const [finalId, setFinalId] = useState()
    const [finalListId, setFinalListId] = useState()
    const [selectedId, setSelectedId] = useState(null);
    const [foldedStates, setFoldedStates] = useState({});
    const [selectedPost, setSelectedPost] = useState(null);
    //Pour récuperer ce qu'il y a dans le FrontProduct
    const [selectedItems, setSelectedItems] = useState({
        customer: [],
        finallists: [],
        option_managements: [],
        testuns: [],
        finallistuns: []
    });


    const { idlist } = useParams()

    const handleClickClient = () => {
        setClickClient(!clickClient)
        setClickOption(false);
        setClickProduct(false);
    }

    const handleClickProduct = () => {
        setClickProduct(!clickProduct);
        setClickOption(false);
        setClickClient(false);
    }

    const handleClickOption = () => {
        setClickOption(!clickOption);
        setClickProduct(false);
        setClickClient(false);
    }

    const handleSelectedIdChange = (newSelectedId) => {
        setSelectedId(newSelectedId);
    };

    useEffect(() => {
        axios.get(url.api_url + '/api/tests?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*&sort=id:desc')
            .then(res => {
                setFinalLists(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (selectedId !== null && posts) {
            const filteredPosts = posts.filter(post => post.id === selectedId);
            filteredPosts.forEach(post => {
                setFinalId(post.attributes.idfinal);
            });
        }
    }, [selectedId, posts]);

    const handleSubmit = (values) => {
        console.log('Form selectedItems:', selectedItems);
        const formData = new FormData()
        formData.append('ref', 'api::beorder.beorder')
        formData.append('refId', evtId)
        const uniqueFinallists = [...new Set(selectedItems.finallists)];
        const uniqueCustomers = [...new Set(selectedItems.customer)];
        const uniqueOptionManagements = [...new Set(selectedItems.option_managements)];
        const uniqueTestuns = [...new Set(selectedItems.testuns)];
        const uniqueFinallistuns = [...new Set(selectedItems.finallistuns)]
        axios.post(url.api_url + '/api/beorders/', {
            "data": {
                "finallists": uniqueFinallists,
                "customer": uniqueCustomers,
                "option_managements": uniqueOptionManagements,
                "testuns": uniqueTestuns,
                "finallistuns": uniqueFinallistuns,
            }

        }, {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer VotreTokenJWT' // Si l'authentification est nécessaire
            }
        })
    };

    const toggleFold = (id) => {
        setFoldedStates(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleClick = (post) => {
        setSelectedPost(post);
    }

    return (
        <div>
            <Formik
                initialValues={{
                }}
            // onSubmit={handleSubmit}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className='front__left'>
                            <div className='front__left__logo'>
                                <img src={url.icon_logo} alt='logo' />
                            </div>


                            <div className='front__left__info'>
                                <ul>
                                    <li className='front__list__open'>
                                        <p>Liste des clients</p>
                                        <ul className={clickClient ? 'front__list__nested' : 'front__list__nested hidden'}>
                                            <FrontCient foldedStates={foldedStates}
                                                toggleFold={toggleFold}
                                                frontFinalListid={finalId}
                                                setFieldValue={setFieldValue}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems} />
                                        </ul>
                                    </li>
                                    <li className='front__list__open'>
                                        <p>Produits</p>
                                        <FrontProduct onSelectedIdChange={handleSelectedIdChange}
                                            selectedItems={selectedItems}
                                            setSelectedItems={setSelectedItems}
                                        />
                                    </li>
                                    <li className='front__list__open'>
                                        <p>Options</p>
                                        <ul className={'front__list__nested'}>
                                            <FrontOption foldedStates={foldedStates}
                                                toggleFold={toggleFold}
                                                frontFinalListid={finalId}
                                                setFieldValue={setFieldValue}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems} />
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='front__list__right'>
                            <div className={finalId ? 'front__border__end' : 'front__list__nested hidden'}>
                                {finalId ? <FrontFinalList foldedStates={foldedStates}
                                    toggleFold={toggleFold}
                                    frontFinalListid={finalId}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItems} />
                                    :
                                    null}
                            </div>
                        </div>
                        <button type="submit" onClick={handleSubmit}>Submit</button>
                        <Link to={url.estimate_api_front} ><p className='btn btn-primary'>Créer</p></Link>

                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default FrontIndex;