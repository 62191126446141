import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import FrontFinishList from './FrontFinishList'
import * as url from '../include/var.js'
import './FrontOption.css'
import './FrontFinishun.css'
import {
    Link,
    useParams
} from "react-router-dom"

function FrontFinish({ idlistpic }) {
    const [posts, setPosts] = useState()
    const [finals, setFinals] = useState()
    const [options, setOptions] = useState()
    const [showExemple, setShowExemple] = useState(false);

    // const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        axios.get(url.api_url + '/api/tests?populate=*')
            .then(res3 => {
                setPosts(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/finals?populate=*')
            .then(res3 => {
                setFinals(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/finallists?populate=*')
            .then(res3 => {
                setOptions(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleImageClick = () => {
        setShowExemple(true);
    }

    return (
        <div>
            {posts?.map((post) => (
                <div className="parentDiv" key={post.id}>
                    {post.id == idlistpic ? <img className="front__centering__img" src={url.api_url + post.attributes.pictest.data.attributes.url} alt="pic of products" /> : null}
                    {finals?.map((final) => (
                        <div key={final.id}>
                            {post.id == idlistpic && post.attributes.idfinal == final.id ? <p>{final.attributes.descriptiontest}</p> : null}
                            {/* {options?.map((option) => (
                                <div key={option.id}>
                                    {option.attributes.final.data && option.attributes.final.data.id && post.id == idlistpic ? console.log("test") : null}
                                </div>
                            ))} */}
                        </div>
                    ))}

                </div>
            ))}


        </div>
    )
}

export default FrontFinish