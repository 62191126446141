import React from 'react'
import * as url from '../include/var.js'
import EstimateApiList from './EstimateList'

function EstimateApiFront() {

    return (
        <div>
            <table class="table">
                <thead class="table-light">
                    <tr>
                        <th>#</th>
                        <th>Nom Entreprise</th>
                        <th>Prix</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <EstimateApiList />
            </table>
        </div>
    )
}

export default EstimateApiFront