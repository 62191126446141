import React, { useState, useEffect, useReducer } from 'react'
import axios from 'axios'
import * as url from '../include/var.js'
import { useField } from 'formik';
import './FrontProduct.css'
import FrontFinishun from './FrontFinishun'
import FrontFinish from './FrontFinish'
import {
    Link,
    useParams
} from "react-router-dom"

function FrontProduct({ onSelectedIdChange, selectedItems, setSelectedItems }) {
    const [posts, setPosts] = useState()
    const [testuns, setTestuns] = useState()
    const { idlist } = useParams()
    const [selectedId, setSelectedId] = useState(null)
    const [finallistuns, setFinallistuns] = useState()

    useEffect(() => {
        axios.get(url.api_url + '/api/tests?populate=*&sort=id:desc')
            .then(res => {
                setPosts(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/testuns?populate=*')
            .then(res3 => {
                setTestuns(res3.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        axios.get(url.api_url + '/api/finallistuns?populate=*')
            .then(res => {
                setFinallistuns(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleImageClick = (id) => {
        setSelectedId(id)
        onSelectedIdChange(id)
    }

    const handleCheckboxClick = (e, id, isProduct) => {
        e.stopPropagation();

        if (isProduct === "firstproduct") {
            if (e.target.checked) {
                // Ajouter l'ID sélectionné et obtenir les données asynchrones
                setSelectedItems(prevState => ({
                    ...prevState,
                    testuns: [...prevState.testuns, id]
                }));

                getAsyncDataAndUpdateFinallistuns(id);
            } else {
                // Supprimer l'ID non sélectionné
                setSelectedItems(prevState => ({
                    ...prevState,
                    testuns: prevState.testuns.filter(item => item !== id)
                }));
            }
        }
    };

    const getAsyncDataAndUpdateFinallistuns = (id) => {
        axios.get(url.api_url + '/api/testuns/' + id)
            .then(response => {
                // Créer un tableau à partir de la réponse, assurant qu'il s'agira toujours d'un tableau
                const idfinalun = [].concat(response.data.data.attributes.idfinalun);

                const matchingFinallistuns = finallistuns.filter(finallistun =>
                    finallistun.attributes.finalun.data &&
                    idfinalun.includes(finallistun.attributes.finalun.data.id)
                );

                // Extraire les ID des matchingFinallistuns si nécessaire
                const matchingFinallistunsIds = matchingFinallistuns.map(finallistun => finallistun.id);

                setSelectedItems(prevState => {
                    // Fusionner les nouveaux ID avec les ID existants dans finallistuns
                    // Utiliser un Set pour éliminer les doublons
                    const updatedFinallistuns = Array.from(new Set([...prevState.finallistuns, ...matchingFinallistunsIds]));

                    // Retourner le nouvel état
                    return { ...prevState, finallistuns: updatedFinallistuns };
                });
            })
            .catch(error => {
                console.error("Erreur lors de la requête axios :", error);
            });
    };


    return (
        <div className='front__list__nested__img'>
            {idlist !== undefined ?
                posts?.map((post) => (
                    <div key={post.id} className={post.attributes.idtest == idlist ? 'd' : 'template'}>
                        {post.attributes.idtest == idlist ? post.attributes.final == false ?
                            <Link to={'/FrontIndex/' + post.id}><img className="card-img-top img-fluid"
                                src={url.api_url + post.attributes.pictest.data.attributes.url}
                                alt="pic of products" /></Link>
                            :
                            <img className="card-img-top img-fluid" src={url.api_url + post.attributes.pictest.data.attributes.url} onClick={() => handleImageClick(post.id)} alt="pic of products" />
                            :
                            null}

                        {selectedId === post.id && <FrontFinish idlistpic={selectedId} />}
                    </div>

                ))
                :
                testuns?.map((testun) => (
                    <div key={testun.id}>
                        <div className={testun.attributes.idtest == idlist ? 'front__list__nested__img' : 'template'}>
                            {testun.attributes.idtest == idlist && testun.attributes.final == false ? <Link to={'/FrontIndex/' + testun.id}><img className="card-img-top img-fluid"
                                src={url.api_url + testun.attributes.pictest.data.attributes.url}
                                alt="pic of products" /></Link>
                                :
                                <>
                                    <input
                                        className='hide__check'
                                        name='testuns'
                                        value={testun.id}
                                        type="checkbox"
                                        id={testun.id}
                                        onClick={(e) => handleCheckboxClick(e, testun.id, "firstproduct")}

                                    />
                                    <label className='bordering__check' htmlFor={testun.id}>
                                        <img className="card-img-top img-fluid"
                                            src={url.api_url + testun.attributes.pictest.data.attributes.url}
                                            alt="pic of products"
                                        />
                                    </label>

                                </>
                            }
                            {selectedId === testun.id && <FrontFinishun idlistpic={selectedId} />}
                        </div>
                    </div>
                )
                )}
        </div>
    )
}

export default FrontProduct