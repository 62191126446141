// URL
// export var api_url ="http://localhost:1337"
export var api_url ="https://api.stagric.fr"

export var log = '/'
export var products = '/products'
export var navi = '/Navi'
export var option_management = '/OptionManagement'
export var grid_edit_productbis = '/GridProductEditbis'
export var grid_edit_productbisun = '/GridProductEditbisun'
export var grid_edit_product_final_bisun = '/GridProductFinalEditbisun'
export var grid_product_bis = '/GridProductbis'
export var product_grid_create_bis = '/ProductGridApibis'
export var product_grid_create_bis_un = '/ProductGridApibisun'
export var product_grid_createfinal_bis_un = '/ProductGridApifinalbisun'
export var product_grid_editfinal_bis_un = '/ProductGridApiEditfinalbisun'
export var product_grid_edit_bis_final = '/ProductGridApiEditfinalbis'
export var product_grid_create_bis_final = '/ProductGridApiFinalCreatebis'
export var option_list = '/OptionList'
export var order_tracking = '/OrderTracking'
export var url_login= api_url + '/api/auth/local'
export var url_register= api_url + '/api/auth/local/register'
export var option_edit = '/OptionEdit'
export var final_grid_list = '/FinalGridList'
export var final_grid_listun = '/FinalGridListun'
export var final_create_list = '/FinalCreateList'
export var final_create_listun = '/FinalCreateListun'
export var final_list_edit = '/FinaListEdit'
export var final_list_editun = '/FinaListEditun'

// Customer
export var customer_contact = '/CustomerContact'
export var customer_form = '/CustomerForm'
export var customer_edit = '/CustomerEdit'

// User
export var user_contact = '/UserContact'
export var user_form = '/UserForm'
export var user_edit = '/UserEdit'

// Front
export var front_index = '/FrontIndex'
export var front_product = '/FrontProduct'
export var front_finish_list = '/FrontFinishList'

// Estimate Front 
// export var estimate_template = '/EstimateTemplate'
export var estimate_api_front = '/EstimateApiFront'
export var estimate_list = '/EstimateList'

// Icons path

export var icon_delete = '/assets/images/icon/icon-trash.png'
export var icon_edit = '/assets/images/icon/icons-crayon.png'
export var icon_logo = '/assets/images/icon/stagric.png'
